<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>编辑账户</span>
			</div>

			<div v-loading="loading">
				<el-form :model="formData" :rules="rules" ref="ruleForm" label-width="80px">
					<el-form-item label="公司名称" prop="realname">
						<el-input v-model="formData.realname" placeholder="请输入公司名称" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item label="统一信用代码" prop="codes">
						<el-input v-model="formData.codes" autocomplete="off" placeholder="请输入统一信用代码"
							class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item label="银行名称" prop="branch_bank_name">
						<el-input v-model="formData.branch_bank_name" autocomplete="off" placeholder="请输入银行名称"
							maxlength="50" class="ns-len-input"></el-input>
					</el-form-item>
					<el-form-item label="对公账号" prop="bank_account">
						<el-input v-model="formData.bank_account" autocomplete="off" placeholder="请输入对公账号"
							maxlength="30" class="ns-len-input"></el-input>
					</el-form-item>
					<el-form-item label="额度"   v-if="formData.status ==1">
						<el-input v-model="quotas" autocomplete="off" disabled readonly="true" class="ns-len-input"></el-input>
					</el-form-item>
					<el-form-item label="备注"  v-if="formData.status==2&&remks!=''">
						<el-input v-model="remks" autocomplete="off" disabled readonly="true" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item v-if="formData.status==0">
						<el-button size="medium" type="info" disabled>待审核</el-button>
					</el-form-item>
					<el-form-item v-else-if="formData.status==1">
						<el-button size="medium" type="warning" @click="saveAccount('ruleForm')">修改信息</el-button>
					</el-form-item>
					<el-form-item v-else-if="formData.status==2">
						<el-button size="medium" type="warning" @click="saveAccount('ruleForm')">重新提交</el-button>
					</el-form-item>
					<el-form-item v-else>
						<el-button size="medium" type="primary" @click="saveAccount('ruleForm')">保存</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		periodDetail,
		periodAccount
	} from "@/api/member/member"
	export default {
		name: "account_edit",
		components: {},
		data() {
			let self = this

			return {
				formData: {
					id: '',
					realname: '',
					codes: '',
					bank_account: '',
					branch_bank_name: '',
					status: '-1'
				},
				quotas: '-1',
				remks: "",
				flag: false, //防重复标识
				payList: [],
				loading: false,
				index: 0,
				transferType: [],
				rules: {
					realname: [{
						required: true,
						message: "请输入公司名称",
						trigger: "blur"
					}],
					codes: [{
						required: true,
						message: "请输入统一信用代码",
						trigger: "blur"
					}],
					branch_bank_name: [{
						required: true,
						message: "请输入银行名称",
						trigger: "blur"
					}],
					bank_account: [{
						required: true,
						message: "请输入对公账号",
						trigger: "blur"
					}],
				},
				yes: true
			}
		},
		created() {
			this.loading = true
			this.getAccountDetail()
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		methods: {

			/**
			 * 获取账户详情（编辑）
			 */
			getAccountDetail() {
				periodDetail().then(res => {
					if (res.code == 0 && res.data) {
						this.formData.realname = res.data.realname;
						this.formData.codes = res.data.codes;
						this.formData.bank_account = res.data.bank_account;
						this.formData.branch_bank_name = res.data.branch_bank_name;
						this.formData.status = res.data.status;
						this.formData.id = res.data.id;
						this.quotas = res.data.quotas
						this.remks = res.data.remks
					}
					this.loading = false
				}).catch(err => {
					this.loading = false
				})
			},
			/**
			 * 保存
			 */
			saveAccount(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var data = {
							id: this.formData.id,
							realname: this.formData.realname,
							codes: this.formData.codes,
							bank_account: this.formData.bank_account,
							branch_bank_name: this.formData.branch_bank_name
						}

						data.url = "add"
						if (this.formData.id) {
							data.url = "edit"
						}

						if (this.flag) return
						this.flag = true

						periodAccount(data)
							.then(res => {
								if (res.code == 0) {
									this.$message({
										message: '提交成功，平台审核中',
										type: "success"
									})
									this.getAccountDetail()

								} else {
									this.flag = false
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.flag = false
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.el-card.is-always-shadow,
	.el-card.is-hover-shadow:focus,
	.el-card.is-hover-shadow:hover {
		box-shadow: unset;
	}

	.el-card {
		border: 0;
	}

	.ns-len-input {
		width: 350px;
	}

	.el-select {
		margin-right: 10px;
	}
</style>